<template>
  <div @keydown.esc="closeMenu">
    <sl-popup
      placement="bottom-end"
      distance="20"
      anchor="app-menu-button"
      :active="menuActive"
      @mouseleave="closeMenu"
    >
      <sl-menu
        ref="menuElContainer"
        class="dnl-bg-slate-200 dnl-min-w-48"
      >
        <template v-for="(item, index) in items">
          <sl-menu-item
            :key="index"
            class="appbar-menu no-checkbox divider"
            :data-testid="item.testId"
            @click="item.actionCallback"
          >
            <dnl-icon
              slot="prefix"
              :icon="item.icon"
              :class="['dnl-text-jeans-indigo dnl-h-4 dnl-w-4', item.iconClass]"
            >
            </dnl-icon>
            <span class="dnl-text-slate-500 dnl-font-medium dnl-text-sm">
              {{ t(item.title) }}
            </span>

            <sl-menu
              v-if="item.subMenu"
              class="dnl-min-w-48"
              slot="submenu"
            >
              <template v-for="(subItem, subMenuIndex) in item.subMenu">
                <sl-menu-item
                  :key="subMenuIndex"
                  class="appbar-menu no-checkbox no-submenu-icon divider"
                  :data-testid="subItem.testId"
                  :data-selected="subItem.selected"
                  @click="subItem.actionCallback"
                >
                  <span
                    slot="prefix"
                    class="dnl-font-bold dnl-text-slate-500!"
                    >{{ subItem.prefix }}</span
                  >
                  <span class="dnl-text-slate-500 dnl-font-medium dnl-text-sm">
                    {{ t(subItem.title) }}
                  </span>
                  <dnl-icon
                    v-if="subItem.selected"
                    icon="mdi-check"
                    slot="suffix"
                    size="4"
                    class="dnl-ms-17 dnl-text-jeans-indigo"
                  >
                  </dnl-icon>
                </sl-menu-item>
              </template>
            </sl-menu>
          </sl-menu-item>
        </template>
      </sl-menu>
    </sl-popup>

    <button
      id="app-menu-button"
      data-testId="app-menu-button"
      class="dnl-flex dnl-items-center dnl-bg-slate-100 dnl-py-[9px] dnl-px-[6px] dnl-rounded-md"
      @click="onBurgerButtonClick"
    >
      <dnl-icon
        class="dnl-h-3.5 dnl-w-5.5"
        icon="app-burger-menu"
      >
      </dnl-icon>
    </button>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import {
    getLanguages,
    setLanguage,
    setLanguageInUserSettings,
    useLanguageStore,
  } from '@/services/language.service';
  import { useI18n } from 'vue-i18n-bridge';
  import { onClickOutside } from '@vueuse/core';
  import { Language } from '@/interfaces/Languages';
  import { logout } from '@/store/modules/auth/auth-actions';
  import { watch } from 'vue';
  import { getAuthToken } from '@/services/auth';

  const { t } = useI18n();

  const menuActive = ref(false);
  const menuElContainer = ref(null);

  const props = defineProps<{
    showFullMenu?: boolean;
  }>();

  const items = computed(() => {
    const languages = getLanguages();
    const languageMenuItems = languages.map(language => {
      return {
        title: `languages.${language.shortCode.toLocaleLowerCase()}`,
        prefix: language.shortCode,
        selected: useLanguageStore.language === language.tag,
        testId: `language-${language.tag}`,
        actionCallback: changeLanguage.bind(null, language),
      };
    });

    const baseItems = [
      {
        title: 'user.action.menu.selectLanguage',
        icon: 'mdi-web',
        subMenu: languageMenuItems,
        testId: 'selectLanguage',
        actionCallback: () => {},
      },

      {
        title: 'user.action.menu.privacy',
        icon: 'privacy-policy',
        actionCallback: navigateToURL.bind(null, 'user.link.privacy.url', '_self'),
      },
      {
        title: 'user.action.menu.imprint',
        icon: 'information-symbol',
        iconClass: 'dnl-ms-1 dnl-h-3! dnl-w-3!',
        actionCallback: navigateToURL.bind(null, 'generic.imprint.url'),
      },
      {
        title: 'user.action.menu.releaseNotes',
        icon: 'mdi-new-box',
        actionCallback: navigateToURL.bind(null, 'user.link.releaseNotes.url'),
      },
    ];

    if (!props.showFullMenu) {
      return baseItems;
    }

    return [
      ...baseItems,
      {
        title: 'user.action.menu.logout',
        icon: 'mdi-logout',
        actionCallback: logout,
      },
    ];
  });

  onClickOutside(
    menuElContainer,
    () => {
      if (!menuActive.value) return;
      menuActive.value = false;
    },
    {
      ignore: ['#app-menu-button'],
      detectIframe: true,
    },
  );

  watch(
    () => useLanguageStore.language,
    async value => {
      if (value) {
        const token = getAuthToken();
        if (token) {
          await setLanguageInUserSettings(value);
        }
      }
    },
  );

  function navigateToURL(urlKey: string, target: string = '_blank') {
    window.open(t(urlKey), target);
  }

  function changeLanguage(language: Language) {
    setLanguage(language.tag);
    menuActive.value = false;
  }

  function onBurgerButtonClick() {
    menuActive.value = !menuActive.value;
  }

  function closeMenu() {
    menuActive.value = false;
  }
</script>

<style scoped>
  sl-menu-item.no-submenu-icon::part(submenu-icon) {
    display: none;
  }

  sl-menu-item.appbar-menu::part(submenu-icon) {
    color: var(--jeans-indigo) !important;
  }

  sl-menu-item.no-checkbox::part(checked-icon) {
    display: none;
  }

  sl-menu-item.appbar-menu::part(prefix) {
    margin-inline-end: var(--spacing-8);
  }

  sl-menu-item.divider:not(:last-child)::part(base) {
    border-bottom: solid var(--slate-200) var(--spacing-1) !important;
  }

  sl-menu-item.appbar-menu::part(base) {
    padding: var(--spacing-6) 1rem !important;
    background: var(--slate-100) !important;
  }

  sl-menu-item.appbar-menu::part(base):hover,
  sl-menu-item.appbar-menu:hover,
  sl-menu-item.appbar-menu:hover::part(base) {
    background: var(--slate-50) !important;
  }

  sl-menu-item.appbar-menu::part(base):hover,
  sl-menu-item.appbar-menu:hover,
  sl-menu-item.appbar-menu:hover::part(base) {
    background: var(--slate-50) !important;
  }
</style>
